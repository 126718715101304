import React, {useEffect, useState} from 'react';
import {Button, Divider, Form, Header, Icon, Input, Message, Modal} from "semantic-ui-react";
import {useForm} from "../../hooks/useForm";
import {useTranslation} from "react-i18next";
import './resetPassword.scss'
import {toast} from "react-toastify";
import {useChangePasswordMutation, useResetPasswordMutation} from "../../store/api/userApi";
import {useActions} from "../../hooks/actions";
import {LOGIN_LINK} from "../../route/links";
import {useNavigate} from "react-router-dom";

const PasswordField = ({name, label, onChange}) => {
    const [type, setType] = useState('password');
    const handleClick = () => setType(type === "text" ? "password" : "text");

    return <Form.Field className={"password-with-eye"}>
        <Icon
            name={type === "text" ? "hide" : "eye"}
            onClick={handleClick}
        />
        <Form.Input
            label={label}
            iconPosition="left"
        >
            <Input
                icon='lock'
                iconPosition='left'
                name={name}
                type={type}
                onChange={onChange}
            />
        </Form.Input>
    </Form.Field>
}

const Check = ({name, isValid}) => {
    const {t} = useTranslation();
    const iconColor = (isValid) => isValid ? 'green' : 'grey';

    return <div>
        <Icon
            name='check'
            color={iconColor(isValid)}
        />
        {t(name)}
    </div>
}

const ChangePasswordModal = ({open, children, id}) => {
    const [modalOpen, setOpenModal] = useState(false);
    const [errorText, setErrorText] = useState(null);

    const {t} = useTranslation();

    const {removeUser} = useActions();
    const navigate = useNavigate();

    const logout = () => {
        removeUser();
        navigate(LOGIN_LINK);
    }

    const initialState = {
        oldPassword: null,
        password: null,
        passwordConfirm: null,
    }

    const [form, setValue, setForm] = useForm(initialState);

    useEffect(() => {
        setOpenModal(open);
    }, [open])

    const onOpen = () => {
        setOpenModal(true);
    }

    const onClose = () => {
        setOpenModal(false);
        setForm(initialState);
        setErrorText(null)
    }

    const validateLength = () => {
        if (!validateEq()) return false;
        let password = form.password;
        return password.length >= 6 && password.length <= 32;
    };

    const validateDigital = () => {
        if (!validateEq()) return false;
        return /^.*(?=.*\d).*$/.test(form.password);
    };

    const validateRegistry = () => {
        if (!validateEq()) return false;
        return /^.*(?=.*[a-z]).*$/.test(form.password)
            && /^.*(?=.*[A-Z]).*$/.test(form.password)
            && /^[a-zA-Z0-9!@#$%^&*)(+=._-]+$/.test(form.password);
    };

    const validateEq = () => {
        return form.password && (form.password === form.passwordConfirm)
    };

    const [resetPassword, {isLoadingReset}] = useResetPasswordMutation();
    const [changePassword, {isLoadingChange}] = useChangePasswordMutation();
    const isLoading = isLoadingChange || isLoadingReset;

    const okBtnClick = () => {
        if (!(validateLength() && validateDigital() && validateRegistry()))
            return;

        (!id ? changePassword : resetPassword)({
            oldPassword: form.oldPassword,
            newPassword: form.password,
            id
        })
            .unwrap()
            .then((res) => {
                if (res.isError) {
                    setErrorText(res.message);
                    res.message && toast.error(res.message);
                } else {
                    onClose();
                    logout();
                    toast.info(t("passwordIsReset"));
                }
            })
            .catch(err => {
                err.message && toast.error(err.message);
                setErrorText(err.message || t('error'))
            });
    };

    const checkList = [
        {
            name: 'passwordsMustMatch',
            isValid: validateEq()
        },
        {
            name: 'passwordMustBeBetween6and32characters',
            isValid: validateLength()
        },
        {
            name: 'passwordMustContainNumbers',
            isValid: validateDigital()
        },
        {
            name: 'passwordMustContainLatinLettersInDifferentCase',
            isValid: validateRegistry()
        },
    ]

    return (
        <Modal
            dimmer="blurring"
            id="fieldModal"
            className="profile-modal"
            trigger={children}
            open={modalOpen}
            onOpen={onOpen}
            size='tiny'
            onClose={onClose}
            closeOnEscape={!open}
            closeOnDimmerClick={false}
            closeIcon={!open}
        >
            <Modal.Header>
                {t("change_pass")}
            </Modal.Header>
            <Modal.Content>
                <Form error>
                    {!id && <>
                        <PasswordField
                            name='oldPassword'
                            label={t("oldPassword")}
                            onChange={setValue}
                        />
                        {
                            errorText && <Message error={true}>
                                {t(errorText)}
                            </Message>
                        }
                        <Divider/>
                    </>}
                    <PasswordField
                        name='password'
                        label={t("newPass")}
                        onChange={setValue}
                    />
                    <PasswordField
                        name='passwordConfirm'
                        label={t("retypePass")}
                        onChange={setValue}
                    />
                    <Message>
                        {
                            checkList.map(check => <Check
                                key={check.name}
                                name={check.name}
                                isValid={check.isValid}
                            />)
                        }
                    </Message>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    disabled={isLoading || !(validateLength() && validateDigital() && validateRegistry())}
                    positive
                    content={t("setupPass")}
                    onClick={okBtnClick}
                    loading={isLoading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default ChangePasswordModal;