import React, { useEffect, useState } from 'react';
import { Button, Confirm, Form, Message, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Search from "../inputs/Search";
import { BOOLEAN, HIDDEN_TYPE, MULTISELECT, TEXT } from "../../constants/fieldTypes";
import DragAndDrop from "../dragAndDrop";
import { useDeleteMutation, useSaveMutation } from "../../store/api/dictionaryApi";
import { toast } from "react-toastify";
import FieldComponent from "../fields";
import { useSelector } from "react-redux";
import { userInfoSelector } from "../../store/slices/userSlice";
import {REPRESENTATION_DICTIONARY_NAME} from "../../constants/options";

const RepresentationModal = ({ gridName, modalOpen, onClose, representation, columns, changeRepresentation }) => {
    const { t } = useTranslation();
    const dictionaryName = REPRESENTATION_DICTIONARY_NAME;

    const [name, setName] = useState('');
    const [roles, setRoles] = useState(null);
    const [associateWithRoles, setAssociateWithRoles] = useState(false);
    const [search, setSearch] = useState('');
    const [error, setError] = useState(false);
    const [selectedFields, setSelectedFields] = useState([]);
    const [isEmpty, setEmpty] = useState(false);
    const [confirmation, setConfirmation] = useState({ open: false });
    const userInfo = useSelector(userInfoSelector);

    const [deleteRepresentation, { isLoading: loadingDelete }] = useDeleteMutation();
    const [saveRepresentation, { isLoading: loadingSave }] = useSaveMutation();

    useEffect(() => {
        setName(representation?.name);
        setSelectedFields(representation?.properties?.fields?.map(v =>
            columns.find(col => col.name === v.propName)
        ) || []);
        setRoles(representation?.properties?.roles);
        setAssociateWithRoles(!!representation?.properties?.roles?.length);
        return () => {
            setError(false);
            setEmpty(false);
        }
    }, [representation])

    const closeConfirmation = () => {
        setConfirmation({ open: false });
    };

    const showConfirmation = (content, onConfirm) => {
        setConfirmation({
            open: true,
            content,
            onConfirm,
        });
    };

    const isNew = !representation?.id;

    const fieldsList = columns.filter(column => {
        return !selectedFields.map(item => item?.name)
            .includes(column.name)
            && column.type !== HIDDEN_TYPE;
    });

    const onChange = selected => {
        setSelectedFields(selected);
        setEmpty(false);
    };

    const handleDelete = () => {
        showConfirmation(
            t('confirmDeleteRepresentation'),
            () => {
                deleteRepresentation({
                    name: dictionaryName,
                    ids: [representation.id]
                }).then(() => {
                    changeRepresentation(null);
                    onClose();
                    setConfirmation({ open: false })
                })
            }
        )
    }

    const handleSave = () => {
        if (!name) {
            setError('requiredField');
        } else if (!selectedFields.length) {
            setError(null);
            setEmpty(true);
        } else {
            saveRepresentation({
                name: dictionaryName,
                form: {
                    name,
                    id: representation.id,
                    properties: {
                        roles,
                        entityType: gridName,
                        fields: selectedFields.map((f, i) => ({
                            propName: f.name,
                            order: i,
                            customFieldId: f.isCustom && f.customFieldId
                        }))
                    }
                }
            }).unwrap().then((res) => {
                if (!res.isError && res.id) {
                    setError(null);
                    setEmpty(false);
                    changeRepresentation(res.id);
                    onClose();
                } else {
                    toast.error(t(res?.message || 'error'))
                }
            }).catch(e => {
                toast.error(t(e?.data?.message || 'error'))
            })
        }
    }

    return (
        <Modal
            dimmer="blurring"
            id="fieldModal"
            open={modalOpen}
            onClose={onClose}
            className="representation-modal"
            closeIcon
        >
            <Modal.Header>
                {`${!isNew
                    ? t('editRepresentation', { name: representation?.name })
                    : t('createRepresentation')
                    } ${userInfo?.currentPlatform?.name ? t('forPlatform', { name: userInfo.currentPlatform.name }) : t('forAllPlatforms')}`}
            </Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Form style={{ marginBottom: '16px' }}>
                        <FieldComponent
                            type={TEXT}
                            name='name'
                            value={name}
                            error={error && t(error)}
                            onChange={(e, { value }) => setName(value)}
                        />
                        <Form.Group widths='equal'>
                            <FieldComponent
                                className='margin-top-7'
                                width={4}
                                type={BOOLEAN}
                                name='associateWithRoles'
                                label='associateWithRoles'
                                value={associateWithRoles}
                                onChange={(e, { checked }) => {
                                    setRoles(null);
                                    setAssociateWithRoles(checked)
                                }}
                            />
                            <FieldComponent
                                noLabel
                                width={12}
                                type={MULTISELECT}
                                name='roles'
                                source='roles'
                                value={roles}
                                onChange={(e, { value }) => setRoles(value)}
                                isDisabled={!associateWithRoles}
                            />
                        </Form.Group>
                        <Search
                            size="mini"
                            isAuto
                            value={search}
                            onChange={(e, { value }) => setSearch(value)}
                        />
                    </Form>
                    <div className="flex-container-justify">
                        {(fieldsList && fieldsList.length > 0) ||
                            (selectedFields && selectedFields.length) ? (
                            <DragAndDrop
                                key={'dnd' + gridName}
                                type={gridName}
                                left={fieldsList}
                                right={selectedFields}
                                search={search}
                                t={t}
                                onChange={onChange}
                            />
                        ) : null}
                    </div>
                    {isEmpty ? (
                        <Message negative>{t('pleaseAddFields')}</Message>
                    ) : null}
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions className="grid-card-actions">
                <div>
                    {
                        !isNew && <Button
                            color="red"
                            onClick={handleDelete}
                            disabled={loadingDelete || loadingSave}
                            loading={loadingDelete}
                        >
                            {t('delete')}
                        </Button>
                    }
                </div>
                <div>
                    <Button
                        color="grey"
                        onClick={onClose}
                    >
                        {t('cancelButton')}
                    </Button>
                    <Button
                        color="blue"
                        onClick={handleSave}
                        disabled={loadingDelete || loadingSave}
                        loading={loadingSave}
                    >
                        {t('saveButton')}
                    </Button>
                </div>
            </Modal.Actions>
            <Confirm
                dimmer="blurring"
                open={confirmation.open}
                onCancel={closeConfirmation}
                cancelButton={t('cancelConfirm')}
                onConfirm={confirmation.onConfirm}
                content={confirmation.content}
            />
        </Modal>
    );
};

export default RepresentationModal;