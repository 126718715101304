import React, {useState} from 'react';
import {Button, Form} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import {useTranslation} from 'react-i18next';
import {dateFormatForFilter, dateToString, parseDate, parseDateTime} from "../../utils/dateTime";

const Facet = ({name, value, onChange, scrollOff, withTime}) => {
    const {t} = useTranslation();

    const getStringItem = i => {
        const parts = (value || '').split('-');
        return parts[i] || null;
    };

    const getDateItem = i => {
        let s = getStringItem(i);
        if (s) return withTime ? parseDateTime(s) : parseDate(s);
        return null;
    };

    let [isPeriod, setIsPeriod] = useState(Boolean(getStringItem(1)));

    const callbackOnChange = (start, end) => {
        let value = '';
        if (start && !end) {
            value = start;
        } else if (start && end) {
            value = [start, end].join('-');
        }

        if (onChange !== undefined) onChange(new Event('change'), {name, value});
    };

    const toggleStart = value => {
        let start = withTime ? dateFormatForFilter(value) : dateToString(value);
        if (start === getStringItem(0)) start = null;
        callbackOnChange(start, getStringItem(1));
    };

    const toggleEnd = value => {
        let end = withTime ? dateFormatForFilter(value) : dateToString(value);
        if (end === getStringItem(1)) end = null;
        callbackOnChange(getStringItem(0), end);
    };

    const handlePeriod = () => {
        setIsPeriod(true);
        !scrollOff && setTimeout(() => window.scrollBy(1, 0));
    };

    const handleRestClick = () => {
        callbackOnChange(null, null);
        setIsPeriod(false);
    };

    const addProps = withTime ? {
        dateFormat: "dd.MM.yyyy HH:mm:ss",
        showTimeInput: true,
        timeFormat: "HH:mm:ss",
        timeInputLabel: `${t("time")}:`
    } : {
        dateFormat: "dd.MM.yyyy"
    }

    return (
        <div className="facet-input width-mc">
            <div className="reset-selected">
                <span onClick={handleRestClick}>{t('resetSelected')}</span>
            </div>
            <Form className="filter-popup">
                <Form.Group>
                    <Form.Field width={isPeriod ? 8 : 16}>
                        <DatePicker
                            inline
                            locale="ru"
                            selected={getDateItem(0) || null}
                            allowSameDay
                            onChange={toggleStart}
                            {...addProps}
                        />
                    </Form.Field>
                    {isPeriod ? (
                        <Form.Field width={8}>
                            <DatePicker
                                inline
                                locale="ru"
                                disabled={!getDateItem(0)}
                                selected={getDateItem(1) || null}
                                minDate={getDateItem(0)}
                                allowSameDay
                                onChange={toggleEnd}
                                {...addProps}
                            />
                        </Form.Field>
                    ) : null}
                </Form.Group>
            </Form>
            <Button size="mini" compact disabled={isPeriod || !getDateItem(0)} onClick={handlePeriod}>
                {t('choosePeriod')}
            </Button>
        </div>
    );
};

export default Facet;
