import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQuery} from "./baseQuery";

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQuery(),
    endpoints: (build) => ({
        appConfig: build.mutation({
            query: () => {
                return {
                    url: '/appConfiguration'
                }
            }
        }),
        login: build.mutation({
            query: ({login, password, language, roleId}) => {
                return {
                    url: '/identity/login',
                    method: 'POST',
                    body: {
                        login,
                        password,
                        language,
                        roleId
                    }
                }
            }
        }),
        changeRole: build.mutation({
            query: ({roleId}) => {
                return {
                    url: `/identity/changeRole?roleId=${roleId}`,
                    method: 'POST',
                }
            }
        }),
        changePlatform: build.mutation({
            query: ({platformId}) => {
                return {
                    url: `/identity/changePlatform?platformId=${platformId}`,
                    method: 'POST',
                }
            }
        }),
        changeActivity: build.mutation({
            query: ({activityId}) => {
                return {
                    url: `/identity/changeActivity?activityId=${activityId}`,
                    method: 'POST',
                }
            }
        }),
        userInfo: build.mutation({
            query: () => {
                return {
                    url: '/identity/userInfo',
                    method: 'POST',
                }
            }
        }),
        getProfile: build.query({
            query: () => {
                return {
                    url: '/profile/get',
                }
            }
        }),
        forgotPassword: build.mutation({
            query: ({email, language}) => {
                return {
                    url: '/PasswordRecovery/CreateRequest',
                    method: 'POST',
                    body: {
                        email,
                        language
                    }
                }
            }
        }),
        resetPassword: build.mutation({
            query: ({oldPassword, newPassword, id}) => {
                return {
                    url: '/PasswordRecovery/resetPassword',
                    method: 'POST',
                    body: {
                        verificationUid: id,
                        oldPassword,
                        newPassword,
                        confirmPassword: newPassword
                    }
                }
            }
        }),
        changePassword: build.mutation({
            query: ({oldPassword, newPassword}) => {
                return {
                    url: '/identity/changePassword',
                    method: 'POST',
                    body: {
                        currentPassword: oldPassword,
                        newPassword
                    }
                }
            }
        }),
        saveProfile: build.mutation({
            query: ({form}) => {
                return {
                    url: '/profile/save',
                    method: 'POST',
                    body: form
                }
            }
        }),
    })
});

export const {
    useLoginMutation,
    useUserInfoMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useChangePasswordMutation,
    useSaveProfileMutation,
    useLazyGetProfileQuery,
    useAppConfigMutation,
    useChangeRoleMutation,
    useChangePlatformMutation,
    useChangeActivityMutation,
} = userApi;