import React from 'react';
import {Outlet} from "react-router-dom";
import Header from "../components/header/Header";
import LoadingSegment from "../components/LoadingSegment";

const Page = ({loading}) => {
    return (
        <>
            <Header/>
            <LoadingSegment
                isLoading={loading}
            >
                {!loading && <Outlet/>}
            </LoadingSegment>

        </>
    );
};

export default Page;