import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import {getLanguage} from "./localeStorageHelper";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        // we init with resources
        fallbackLng: 'en',
        lng: getLanguage() || 'ru',
        debug: false,

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',
        backend: {
            loadPath: '/api/translations/GetForLangType/{{lng}}',
        },
        keySeparator: false, // we use content as keys
        react: {
            useSuspense: true
        },
    });