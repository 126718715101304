import React, {useEffect} from 'react';
import FieldComponent from "../fields";
import {Form} from "semantic-ui-react";
import './style.scss'
import {useSelector} from "react-redux";
import {gridOrDictionaryFieldsSelector, userInfoSelector} from "../../store/slices/userSlice";
import {displayModes} from "../../constants/options";
import {
    arrayTypes,
    customFieldsName,
    disableRemoveSelectedFieldNames,
    MULTISELECT_WITH_ALL,
    MULTISELECT_WITH_ALL_PLUS_EMPTY,
    STATE
} from "../../constants/fieldTypes";
import {isObject} from "lodash";
import {defaultFunc} from "../../utils/stateHelper";
import LoadingSegment from "../LoadingSegment";
import {getFiltersForField} from "../../constants/utils";
import {styleForFields} from "../../utils/styleForFields";
import {useTranslation} from "react-i18next";
import {useDeepEffect} from "../../hooks/useDeepEffect";

const FormComponent = ({
                           name,
                           form,
                           setValue,
                           errors,
                           modalContent,
                           colsCounts: colsCnt,
                           withCreateFor = [],
                           getFieldSettings = defaultFunc,
                           getCustomFieldSettings = defaultFunc,
                           getFieldSettingsWithId,
                           getCustomCols: getCustomColumns = defaultFunc,
                           platformId,
                           readOnly,
                           preFieldSettings,
                           section,
                           customBlock,
                           fields: initFields,
                           customWidths,
                           parentForm,
                           onLoadValues,
                           getData,
                           stateValue: stateValueInit,
                           isChanged
                       }) => {
    const {t} = useTranslation();
    const userInfo = useSelector(userInfoSelector);

    const [getCustomCols, {data: customFields, isLoading, isFetching}] = getCustomColumns();
    const fields = useSelector(state => initFields ? initFields : gridOrDictionaryFieldsSelector(state, name, displayModes.CARD, section));

    const [getFieldSettingsRequest, {
        data: fieldSettings,
        isLoading: fieldSettingsLoading,
        isFetching: fieldSettingsFetching
    }] = getFieldSettings();

    const [getCustomFieldSettingsRequest, {
        data: customFieldSettings,
        isLoading: customFieldSettingsLoading,
        isFetching: customFieldSettingsFetching
    }] = getCustomFieldSettings();


    const getParams = (form) => ({
        name,
        activityId: form?.activityId?.value || null,
        platformId: form?.platformId?.value || platformId || (userInfo?.currentPlatform?.value || null),
    })

    useEffect(() => {
        if ( //если оба значения = undefined, значит ни default, ни запрос по id, еще не отработали
            form?.activityId !== undefined || form?.platformId !== undefined
        ) {
            const params = getParams(form);
            getCustomCols(params)
            getCustomFieldSettingsRequest(params)
        }
    }, [form?.activityId, form?.platformId, platformId, name]);

    useEffect(() => {
        (!getFieldSettingsWithId || form.id) && getFieldSettingsRequest({
            ...getParams(form),
            id: getFieldSettingsWithId && form.id
        })
    }, [form?.activityId || null, form?.platformId || null, platformId || null, name, form.id || null])

    const colsCounts = colsCnt ? colsCnt : (fields.length > 5 ? 2 : null);

    const style = (field) => styleForFields(colsCounts, field && customWidths && customWidths?.[field.name]);

    const setCustomField = (col, {value, name}) => {
        const val = {
            id: col.customFieldId,
            customFieldType: col.customFieldType,
            code: name,
            value
        };

        setValue(null, {
            name: customFieldsName,
            value: form[customFieldsName] ? [
                ...form[customFieldsName].filter(v => v.id !== col.customFieldId),
                val
            ] : [val]
        })
    }

    const getCustomValue = (id) => {
        return form[customFieldsName] && form[customFieldsName].find(f => f.id === id)?.value
    }

    const valueByIsVisiableFieldName = (field) => {
        return isObject(form[field.isVisiableFieldName])
            ? form[field.isVisiableFieldName].value
            : form[field.isVisiableFieldName];
    }

    const fieldIsView = (field) => {
        return field.isVisiableValue ? field.isVisiableValue.includes(
            valueByIsVisiableFieldName(field)
        ) : (
            Array.isArray(form[field.isVisiableFieldName])
                ? !!form[field.isVisiableFieldName].length
                : form[field.isVisiableFieldName]
        )
    }

    useDeepEffect(() => {
        fields.filter(v => v.isVisiableFieldName).forEach(field => {
            if (!fieldIsView(field) && form[field.name]) {
                setValue(null, {
                    name: field.name,
                    value: arrayTypes.includes(field.type) ? [] : null
                })
            }
        })
    }, [form, fields]);

    const stateName = fields?.find(f => f.type === STATE)?.name;
    const stateValue = stateValueInit || (stateName && form[stateName]?.value)

    const getFields = (fields) => {
        return fields?.map(field => {
            const view = field.isVisiableFieldName
                ? fieldIsView(field)
                : true;

            return view && <FieldComponent
                {...field}
                isChanged={isChanged}
                isDisabled={readOnly || field.isDisabled || (field.readOnlyDependOnFields?.length && !field.readOnlyDependOnFields.every(f => Array.isArray(form[f]) ? form[f].length : !!form[f]))}
                style={colsCounts && style(field)}
                forSelectValues={field.refValuesByField ? form[field.refValuesByField] : field.forSelectValues}
                key={field.name}
                error={errors && errors[field.name]}
                onChange={field.isCustom ? (e, o) => setCustomField(field, o) : setValue}
                value={field.isCustom ? getCustomValue(field.customFieldId) : form[field.name]}
                label={field.displayNameKey}
                disabledAll={field.type === MULTISELECT_WITH_ALL && !form[`${field.name}AllAvailable`]}
                form={field.type === MULTISELECT_WITH_ALL_PLUS_EMPTY && form}
                withCreate={withCreateFor.includes(field.name)}
                fieldSettings={[...(preFieldSettings || []), ...(fieldSettings || []), ...(customFieldSettings || [])]}
                stateValue={stateValue}
                filter={getFiltersForField(field, form, platformId, userInfo?.currentPlatform?.value, parentForm)}
                onLoadValues={onLoadValues}
                disableRemoveSelected={disableRemoveSelectedFieldNames.includes(field.name)}
            />
        })
    }

    return (
        <Form>
            <LoadingSegment
                isLoading={
                    fieldSettingsLoading
                    || fieldSettingsFetching
                    || isLoading
                    || isFetching
                    || customFieldSettingsLoading
                    || customFieldSettingsFetching
                }
                className={`form-fields ${colsCounts ? 'form-fields_custom' : ''}`}
            >
                {
                    getFields(modalContent ? fields : [...fields, ...(customFields || []).map(x => ({...x, isCustom: true}))])
                }
                {
                    modalContent && <>
                        {modalContent({form, setValue, errors, name, fieldSettings, getData, stateValue})}

                        {
                            !!customFields?.length && <>
                                <label className='form-fields__label'>{t('additionalFields')}</label>
                                <div className='form-fields form-fields_block'>
                                    {getFields((customFields || []).map(x => ({...x, isCustom: true})))}
                                </div>
                            </>
                        }
                    </>
                }
                {
                    customBlock && customBlock({style: colsCounts && style()})
                }
            </LoadingSegment>
        </Form>
    );
};

export default FormComponent;