import React from 'react';
import ChangePasswordModal from "../../components/modals/ChangePasswordModal";
import {useParams} from "react-router-dom";

const PasswordRecoveryPage = () => {
    const {id} = useParams();

    return (
        <div>
            <ChangePasswordModal open id={id}/>
        </div>
    );
};

export default PasswordRecoveryPage;