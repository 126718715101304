import {DICTIONARIES_SETTINGS_NAME, FIELD_MATRIX_NAME} from "../constants/options";

export const HOME_LINK = '/'
export const GRID_LINK = ':gridName'
export const TRANSPORTS_LINK = 'transports'
export const LOGIN_LINK = '/login'
export const CREATE_INVITATION_LINK = '/createInvitation'
export const SURFACE_LINK = '/surface'
export const CREATE_VISIT_FROM_BJ_LINK = '/createVisitFromBJ'
export const SECURITY_SERVICE_LINK = '/securityService'
export const PASSWORD_RECOVERY_LINK = '/passwordRecovery/:id'
export const FIELD_MATRIX_LINK = `/${FIELD_MATRIX_NAME}`
export const DICTIONARIES_SETTINGS_LINK = `/${DICTIONARIES_SETTINGS_NAME}`