import React, {useEffect, useState} from 'react';
import {BOOLEAN, DATE, DATE_TIME, ENUM, IS_ACTIVE, MULTISELECT, SELECT, STATE, TEXT} from "../../constants/fieldTypes";
import {Icon, Popup} from "semantic-ui-react";
import './style.scss'
import Text from "./Text";
import Date from "./Date";
import Select from "./Select";
import Bool from "./Bool";


const filterComponents = {
    [TEXT]: <Text/>,
    [DATE_TIME]: <Date withTime/>,
    [DATE]: <Date/>,
    [SELECT]: <Select/>,
    [STATE]: <Select/>,
    [MULTISELECT]: <Select/>,
    [ENUM]: <Select/>,
    [BOOLEAN]: <Bool/>,
    [IS_ACTIVE]: <Bool/>,
}

const FilterComponent = (props) => {
    const {
        filters,
        sort,
        setSort,
        onChange,
        name,
        type,
        isSortingEnabled,
        notFilter,
        notSortAndFilter,
        isCustom
    } = props;
    const value = filters[name] || null;
    const sortValue = (sort && sort.name === name)
        ? (sort.desc ? "desc" : "asc")
        : null;
    const [filterValue, setFilterValue] = useState(value);

    useEffect(() => {
        setFilterValue(value)
    }, [value])

    const filterChange = (e, {value}) => {
        setFilterValue(value)
    };

    const onClose = () => {
        onChange(null, {name, value: filterValue})
    }

    return (
        !notSortAndFilter && <div className='filter-component'>
            {!notFilter && <Popup
                className='filter-popup'
                trigger={<Icon
                    color={value ? 'blue' : 'grey'}
                    name='filter'
                />}
                content={
                    React.cloneElement(
                        filterComponents[type] || <Text/>,
                        {
                            ...props,
                            value: filterValue,
                            onChange: filterChange,
                            onClose
                        })
                }
                position='bottom right'
                on='focus'
                onClose={onClose}
            />}
            {isSortingEnabled && <div className='filter-component__sort-buttons'>
                <Icon
                    color={(sortValue && sortValue === 'asc') ? 'blue' : 'grey'}
                    onClick={() => setSort(null, {name, value: 'asc', isCustom})}
                    name="caret up"
                />
                <Icon
                    color={(sortValue && sortValue === 'desc') ? 'blue' : 'grey'}
                    onClick={() => setSort(null, {name, value: 'desc', isCustom})}
                    name="caret down"
                />
            </div>}
        </div>
    );
};

export default FilterComponent;