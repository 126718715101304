import React, {useEffect} from 'react';
import './App.css';
import {MainRoute} from "./route";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.min.css";
import {useAppConfigMutation, useUserInfoMutation} from "./store/api/userApi";
import {useActions} from "./hooks/actions";
import {useSelector} from "react-redux";
import {isAuthSelector, triggerSelector} from "./store/slices/userSlice";
import LoadingSegment from "./components/LoadingSegment";
import {TOAST_AUTO_CLOSE_TIME} from "./constants/options";


function App() {
    const [getUserInfo, {data, isLoading, isError}] = useUserInfoMutation();
    const [getAppConfig, {data: appConfig, isLoading: configLoading}] = useAppConfigMutation();

    const isAuth = useSelector(isAuthSelector);
    const trigger = useSelector(triggerSelector);

    const {setUserInfo, setAppConfig} = useActions();

    useEffect(() => {
        if (isAuth) {
            getUserInfo();
            getAppConfig();
        }
    }, [isAuth, trigger]);

    useEffect(() => {
        data && setUserInfo(data)
    }, [data]);

    useEffect(() => {
        appConfig && setAppConfig(appConfig)
    }, [appConfig]);

    return (
        <div className="App">
            <LoadingSegment
                className='page-segment'
                isLoading={isLoading}
            >
                <MainRoute loading={!appConfig || configLoading}/>
            </LoadingSegment>
            <ToastContainer
                closeOnClick={false}
                draggable={false}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={TOAST_AUTO_CLOSE_TIME}
                hideProgressBar={true}
            />
        </div>
    );
}

export default App;
