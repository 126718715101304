import moment from "moment";

export const formatDate = (date) => {

    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd;

    let mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;

    const yy = date.getFullYear();

    return dd + '.' + mm + '.' + yy;
};

export const parseDate = (date) => {
    if (!date) {
        return null;
    }

    const value = moment(date, "DD.MM.YYYY");
    if (!value.isValid()) {
        return null;
    }

    return value.toDate();
};

export const parseTime = (time) => {
    if (!time) {
        return null;
    }

    const value = moment(time, "HH:mm");
    if (!value.isValid()) {
        return null;
    }

    return value.toDate();
}

export const formatTime = (date) => {

    let hh = date.getHours();
    if (hh < 10) hh = '0' + hh;

    let mm = date.getMinutes();
    if (mm < 10) mm = '0' + mm;

    let ss = date.getSeconds();
    if (ss < 10) ss = '0' + ss;

    return hh + ':' + mm + ':' + ss;
};

export const formatDateTime = (date) => {
    return `${formatDate(date)} ${formatTime(date)}`
};

export const convertingStringToNumber = (str) => {
    if (str === '') return null;
    return parseFloat(str.replace(',', '.').replace(' ', ''))
}


export const dateFormatForFilter = (date) => {
    return dateFormat(date, "DD.MM.YYYY HH:mm:ss")
}

export const dateFormat = (date, format = "DD.MM.YYYY HH:mm", isNotUtc) => {
    if (!date) {
        return null;
    }

    if (isNotUtc) {
        return moment.utc(date).format(format);
    }

    const stillUtc = moment.utc(date).toDate();
    const local = moment(stillUtc).local().format(format);

    return local;
};

export const parseDateTime = (dateString) => {
    if (!dateString)
        return null;
    let reg = /(\d{2}).(\d{2}).(\d{4}) (\d{2}):(\d{2})(:(\d{2}))?/;
    let dateArray = reg.exec(dateString);
    if (!dateArray)
        return null;
    let d = new Date(
        (+dateArray[3]),
        (+dateArray[2]) - 1,
        (+dateArray[1]),
        (+dateArray[4]),
        (+dateArray[5]),
        (+dateArray[7]) || '00'
    );
    return isNaN(d.getTime()) ? null : d;
};

export function parseDateGlobal(dateString) {
    if (!dateString)
        return null;
    try {
        const parts = dateString.split(".");
        let d = new Date(parts[2], parts[1] - 1, parts[0]);
        return isNaN(d.getTime()) ? null : d;
    } catch (e) {
        return null;
    }
};

export function dateTimeToString(dateTime){
    return dateTime.toLocaleDateString().split("/").join(".") + " " + dateTime.toLocaleTimeString("en-US", { hour12: false }).substring(0, 5);
}

export function dateToString(date){
    return date.toLocaleDateString().split("/").join(".")
}

export function compareTimes(time1, time2) {
    const date1 = new Date("2000-01-01T" + time1);
    const date2 = new Date("2000-01-01T" + time2);

    if (date1 < date2) {
        return -1;
    } else if (date1 > date2) {
        return 1;
    } else {
        return 0;
    }
}