import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import { store } from "./store";
import 'semantic-ui-css/semantic.min.css';
import { BrowserRouter } from "react-router-dom";
import './utils/i18n'
import ru from "date-fns/locale/ru";
import enGB from "date-fns/locale/en-GB";
import { registerLocale } from "react-datepicker";
import {Loader} from "semantic-ui-react";

//date-fns
registerLocale("ru", ru);
registerLocale("en", enGB);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Provider store={store}>
            <Suspense fallback={<Loader active/>}>
                <App />
            </Suspense>
        </Provider>
    </BrowserRouter>
);